import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Box, Heading, Text, VStack, Button, Grid } from "@chakra-ui/react";
import Container from "src/components/Container";
import HomeBanner from "src/components/banners/HomeBanner";
import RollingBike from "src/components/RollingBike";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const getData = graphql`
  {
    markdownRemark(fields: { slug: { eq: "/pages/home/" } }) {
      frontmatter {
        banner_heading
        banner_text
        section_one
        section_two
      }
    }
  }
`;

const Home = () => {
  const data = useStaticQuery(getData);
  const page = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <HeadSEO
        title="Penny Farthing Races & Country Fair | Evandale Village Fair"
        description="The wheels of time turn back over a hundred years as the peaceful village of Evandale in northern Tasmania once again plays host to the Evandale Village Fair and National Penny Farthing Championships."
        slug="/"
      />
      <HomeBanner
        overlayOpacity="0.75"
        overlay="#223843"
        backgroundPosition="65% 60%"
      >
        <Container textAlign="center" size="sm" color="white">
          <VStack spacing="1.5rem">
            <Box w="50%" minW="4rem" maxW="12rem">
              <RollingBike />
            </Box>
            <Text fontSize="1.25rem">{page.banner_text}</Text>
            <Button
              as="a"
              href="https://events.humanitix.com/evandale-penny-farthing-and-village-fair"
              colorScheme="red"
              bg="red.600"
              _hover={{ bg: "red.700" }}
              rounded="0"
            >
              Get Tickets
            </Button>
          </VStack>
        </Container>
      </HomeBanner>
      <Container size="md" py="6rem">
        <VStack align="flex-start" spacing="2rem">
          <div id="content"></div>
          <ReactMarkdown children={page.section_one} />
        </VStack>
      </Container>
      <Container size="xl">
        <Grid gridTemplateColumns={[null, null, "1fr 1fr"]} gap="3rem">
          <Box
            as={Link}
            to="/the-fair/"
            className="filter"
            display="grid"
            gridAutoRows="1fr"
            placeItems="center"
          >
            <Box gridColumn="1" gridRow="1" position="relative" zIndex="1">
              <StaticImage
                src="../images/fairsquare.jpg"
                alt="young girl wearing medieval helmet smiling"
                width={670}
                height={670}
              />
            </Box>
            <Box
              gridColumn="1"
              gridRow="1"
              w="100%"
              h="100%"
              background="rgba(0,0,0,0.5)"
              position="relative"
              zIndex="2"
            />
            <Heading
              gridColumn="1"
              gridRow="1"
              size="lg"
              as="h3"
              color="white"
              position="relative"
              zIndex="2"
            >
              The Fair
            </Heading>
          </Box>
          <Box
            as={Link}
            to="/the-races/"
            className="filter"
            display="grid"
            gridAutoRows="1fr"
            placeItems="center"
          >
            <Box gridColumn="1" gridRow="1" position="relative" zIndex="1">
              <StaticImage
                src="../images/racesquare.jpg"
                alt="two men racing penny farthing bicycles"
                width={670}
                height={670}
              />
            </Box>
            <Box
              gridColumn="1"
              gridRow="1"
              w="100%"
              h="100%"
              background="rgba(0,0,0,0.5)"
              position="relative"
              zIndex="2"
            />
            <Heading
              gridColumn="1"
              gridRow="1"
              size="lg"
              as="h3"
              color="white"
              position="relative"
              zIndex="2"
            >
              The Races
            </Heading>
          </Box>
        </Grid>
      </Container>
      <Container size="md" py="6rem">
        <VStack align="flex-start" spacing="2rem">
          <ReactMarkdown children={page.section_two} />
        </VStack>
      </Container>
    </Layout>
  );
};

export default Home;
