import * as React from "react";
import * as styles from "./RollingBike.module.css";

function RollingBike() {
  return (
    <svg viewBox="0 0 48.111 48.11">
      <g fill="#d77a61" class={styles.smallWheel}>
        <path d="M6.108 35.697c3.366 0 6.109 2.74 6.109 6.106a6.117 6.117 0 01-6.109 6.107c-3.325 0-6.03-2.678-6.096-5.987v-.002A.601.601 0 010 41.803a.601.601 0 01.012-.118c.065-3.31 2.77-5.988 6.096-5.988zm0 1.203a4.895 4.895 0 00-4.905 4.903 4.896 4.896 0 004.905 4.904 4.896 4.896 0 004.906-4.904A4.896 4.896 0 006.108 36.9z" />
        <path d="M6.409 36.9v4.178l2.958-2.958.425.425-2.958 2.958h4.18v.601h-4.18l2.958 2.957-.426.426-2.957-2.958v4.178h-.601V42.53L2.85 45.487l-.425-.426 2.957-2.957h-4.18v-.601h4.18l-2.957-2.958.425-.425 2.958 2.958V36.9z" />
      </g>
      <path
        d="M29.318 10.537c10.37 0 18.793 8.42 18.793 18.787 0 10.366-8.424 18.787-18.793 18.787-10.346 0-18.752-8.385-18.79-18.72a-.802.802 0 010-.051-.802.802 0 010-.016-.802.802 0 010-.068c.037-10.334 8.443-18.719 18.79-18.72zm0 1.604c-.914 0-1.811.072-2.687.209l2.688 15.243 2.688-15.243a17.368 17.368 0 00-2.689-.21zm3.033.266l.023.004-.023-.004zm-6.312.047a17.078 17.078 0 00-5.054 1.835l7.742 13.408zm6.56 0L29.91 27.697l7.74-13.408a17.077 17.077 0 00-5.052-1.835zm5.267 1.954l.031.018-.03-.018zm-17.402.181a17.24 17.24 0 00-4.12 3.456l11.86 9.951zm17.709 0l-7.74 13.408 11.86-9.952a17.243 17.243 0 00-4.12-3.456zm-22.214 3.917a17.144 17.144 0 00-2.692 4.655l14.552 5.297zm26.72 0l-11.86 9.952 14.551-5.296a17.143 17.143 0 00-2.691-4.656zm2.897 5.22l-14.552 5.297h15.48a17.158 17.158 0 00-.928-5.297zm-32.515 0a17.17 17.17 0 00-.927 5.297h15.48zm-.931 5.585v.026zm34.374.314H31.025l14.551 5.296a17.17 17.17 0 00.928-5.296zm-18.891 0h-15.48a17.16 17.16 0 00.928 5.296zm.206.565l-14.552 5.296a17.142 17.142 0 002.691 4.655zm3 0l11.86 9.952a17.144 17.144 0 002.691-4.656zm-.387.46l7.74 13.408c1.55-.931 2.94-2.1 4.12-3.456zm-2.227 0l-11.86 9.952a17.243 17.243 0 004.12 3.456zm.522.3l-7.742 13.408a17.077 17.077 0 005.054 1.835zm1.184 0l2.688 15.243c1.8-.347 3.5-.974 5.053-1.835zm-.592.103L26.63 46.297a17.362 17.362 0 005.376 0zm8.578 13.168l-.031.018.031-.018zm-17.154.002c.014.005.017.009.025.014zm5.514 2.012a.432.432 0 01.037.007zm6.118.002l-.025.004.025-.004z"
        fill="#d77a61"
        class={styles.bigWheel}
      />
      <path
        d="M28.516 0l1.604.748v1.858h3.164v.021l.26-.018a.522.522 0 01.08 0 .68.68 0 00.481-.17.679.679 0 00.203-.469.678.678 0 00-.203-.468.681.681 0 00-.482-.17.56.56 0 01-.078-1.116 1.748 1.748 0 011.323.467c.359.334.56.796.56 1.287 0 .49-.2.951-.56 1.286-.36.335-.834.503-1.323.468h-3.429V27.41a-2.076 2.076 0 011.274 1.915-2.076 2.076 0 01-2.076 2.076-2.076 2.076 0 01-2.076-2.076-2.076 2.076 0 011.274-1.915V8.279c-4.192.233-8.314 1.804-11.587 4.433a20.445 20.445 0 00-7.02 11.11c-.732 3.082-.735 6.356-1.151 9.642a38.96 38.96 0 01-1.679 7.382-1.364 1.364 0 01.393.958-1.364 1.364 0 01-1.364 1.363-1.364 1.364 0 01-1.363-1.363-1.364 1.364 0 011.199-1.354 37.79 37.79 0 001.62-7.136c.404-3.185.4-6.506 1.174-9.77a21.638 21.638 0 015.436-9.937c-2.036-.015-2.323-2.26-2.323-2.26s-.257-3.133 1.709-3.126c.203 0 .43.035.684.11 1.74.513 4.854.534 6.86.484-.084.035-.165.072-.247.108a21.626 21.626 0 017.659-1.847zm-7.709 8.945zm-.83.394l-.238.121.238-.12zm-.38.196zm-.346.186zm-.348.195a9.782 9.782 0 00-.221.129l.22-.13zm-.34.199zm-.425.261l-.224.143.224-.143zm-.4.259l-.148.098.148-.098zm-.316.213l-.164.114.164-.114zm-.315.22l-.165.12.165-.12zm-.322.236l-.158.118c.052-.04.105-.08.158-.118zm-.31.235l-.155.122c.052-.041.102-.082.155-.122zm-.408.324l-.178.147.178-.147zm-.333.277l-.149.128.149-.128zm-.303.263l-.131.117c.044-.04.086-.079.13-.117zm-.72.666l-.092.09.092-.09zm-.271.267z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoRollingBike = React.memo(RollingBike);
export default MemoRollingBike;
